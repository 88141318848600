// See https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/

// A map of breakpoints.
$breakpoints: (
    xs: 480px,
    sm: 600px,
    md: 800px,
    lg: 1000px,
    xl: 1200px
);

//
//  RESPOND ABOVE
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// @include above(md) {}
@mixin above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpointValue: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpointValue) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BELOW
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// @include below(md) {}
@mixin below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpointValue: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpointValue - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BETWEEN
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// @include between(md, md) {}
@mixin between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        // Get the lower and upper breakpoints.
        $lowerBreakpoint: map-get($breakpoints, $lower);
        $upperBreakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lowerBreakpoint) and (max-width: ($upperBreakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if map-has-key($breakpoints, $lower) == false {
            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if map-has-key($breakpoints, $upper) == false {
            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}
